<template>
  <div id="app">
    <title>NWSL 2 Austin | The Women's Game in Austin.</title>
    <img
      alt="Austin Skyline"
      src="./assets/skyline.png"
      id = "skyline"
    />
    <table id="topTable" style="">
      <tr>
        <td style="background-color: transparent; border: none">
          <img
            alt="Twitter"
            src="./assets/nwsl2austin.png"
            height="125px"
            width="auto"
            style="padding-right:10px; vertical-align:middle"
          />
        </td>
        <td
          style="background-color: transparent; border: none; text-align: left;"
        >
          <h1><p style="font-size: 50px">NWSL 2 Austin</p></h1>
          <h2>
            <p style="font-style: italic">The Women's Game. Here in Austin.</p>
          </h2>
          <br />
          Let's bring a
          <a href="https://www.nwslsoccer.com" style="color: #ffffff"
            >National Women's Soccer League</a
          >
          Expansion Team to the Capital City! <br /><br />
        </td>
      </tr>
    </table>

    <table id="anthemtable">
      <tr>
        <td>
          Powered by soccer supporters all across Central Texas
        </td>
      </tr>
    </table>

    <div id="maintable">
      <table id="roundedCell" cellspacing="2px">
        <tr >
          <td width="50%">
            <SocialMedia />
          </td>
          <td width="50%">
            <Store />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <NeonForm msg="NWSL in Austin" />
          </td>
        </tr>
      </table>
    </div>
    <Disclaimer />
  </div>
</template>

<script>
import NeonForm from "./components/NeonForm.vue";
import SocialMedia from "./components/SocialMedia.vue";
import Store from "./components/Store.vue";
import Disclaimer from "./components/Disclaimer.vue";

export default {
  name: "App",
  components: {
    NeonForm,
    Disclaimer,
    Store,
    SocialMedia,
  },
  created() {
    document.title = "The Women's Game. Here.";
  },
};
</script>

<style>
#app {
  font-family: Asap, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0;
  background-color: black;
  background-size: 100%;
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
}
* {
  margin: 0;
}
html {
  background-color: black;
}
#maintable {
  display: flex;
  justify-content: center;
  vertical-align: top;
}

#skyline{
  max-width: 50%;
  height: auto;
}

#topTable {
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px dotted grey;
}
#roundedCell {
  max-width: 60%;
}
#roundedCell.tr {
  display:flex;
}
h1 {
  color: #00b140;
}
td {
  background-clip: padding-box;
  justify-content: center;
  color: grey;
  border: 20px solid #282828;
  background-color: #282828;
}
#anthemtable {
  display: flex;
  justify-content: center;
  font-style: italic;
}
html a:link,
a:visited {
  color: #00b140;
}
#anthemtable td {
  background: none;
  border: none;
  justify-content: center;
}
#anthembird {
  max-height: 30px;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  #anthemtable {
    width: 100%;
    font-size: smaller;
    text-align: center;
  }
  #topTable {
    font-size: 80%;
    width: 90%;
    text-align: center;
  }
  /* Force table to not be like tables anymore */
  #roundedCell {
    max-width: 80%;
  }
  #maintable {
    max-width: 100%;
  }
  #roundedCell.tr {
    display: table-row;
    width: 100%;
  }
  #roundedCell.td  {
    display: table-row;
  }
  #skyline{
  max-width: 90%;
  height: auto;
}
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  td {
    /* Behave  like a "row" */
    position: relative;
    width: 95%;
    display: block;
    color: grey;
    border: 5px solid #282828;

    border-spacing: 0px 10px;
  }
}
</style>
